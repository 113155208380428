<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
            <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
          </div>
          <form action="" role="form">
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="parent_id" class="col-form-label col-form-label-sm">Parent/Top Employee <span class="custom-required">*</span></label>
                <select v-model="organization_chart.parent_id" :disabled="organization_chart.parent_selected" v-validate="{ rules: { required: !organization_chart.parent_selected} }" id="parent_id" name="top employee" class="form-control form-control-sm"
                        :class="errors.has('top employee') ? 'pristine untouched is-invalid' : ''" >
                  <option selected :value="''">Select a parent/top</option>
                  <option v-for="(parent_user, index) in parent_users" :key="index" :value="parent_user.id">
                    {{ parent_user.name }} - {{ parent_user.designation }}
                  </option>
                </select>
                <input type="checkbox" class="mt-1" v-model="organization_chart.parent_selected"> Check this option if employee doesn`t need parent/top employee
                <div class="invalid-feedback"> {{ errors.first('top employee') }}</div>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="name" class="col-form-label col-form-label-sm">Employee Name <span class="custom-required">*</span></label>
                <input type="text" v-model="organization_chart.name" v-validate="{ required: true }" id="name" name="employee name" class="form-control form-control-sm" placeholder="Enter name"/>
                <div class="invalid-feedback">{{ errors.first('employee name') }}</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="designation" class="col-form-label col-form-label-sm">Designation/Title <span class="custom-required">*</span></label>
                <input type="text" v-model="organization_chart.designation" v-validate="{ required: true }" id="designation" name="designation" class="form-control form-control-sm" placeholder="Enter designation/title"/>
                <div class="invalid-feedback">{{ errors.first('designation') }}</div>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="location" class="col-form-label col-form-label-sm">Location <span class="custom-required">*</span></label>
                <input type="text" v-model="organization_chart.location" v-validate="{ required: true }" id="location" name="location" class="form-control form-control-sm" placeholder="Enter location"/>
                <div class="invalid-feedback">{{ errors.first('location') }}</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="phone_no" class="col-form-label col-form-label-sm">Phone No. <span class="custom-required">*</span></label>
                <input type="text" v-model="organization_chart.phone_no" v-validate="{ required: true, regex: /^(8801[13456789]{1}[0-9]{8}|01[13456789]{1}[0-9]{8})$/ }" id="phone_no" name="phone_no" class="form-control form-control-sm" placeholder="Enter phone no."/>
                <div class="invalid-feedback">{{ errors.first('phone_no') }}</div>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="department_id" class="col-form-label col-form-label-sm">Department <span class="custom-required">*</span></label>
                <select v-model="organization_chart.department_id" v-validate="'required'" id="department_id" name="department" class="form-control form-control-sm">
                  <option selected :value="''">Select a department</option>
                  <option v-for="(department, index) in departments" :key="index" :value="department.id">{{ department.department_name }} </option>
                </select>
                <div class="invalid-feedback"> {{ errors.first('department') }}</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12 col-sm-12 col-lg-12">
                <label for="assigned_locations" class="col-form-label col-form-label-sm">Assigned Location</label>
                <b-form-tags input-id="tags-basic" v-model="organization_chart.assigned_locations" name="assigned_locations" placeholder="Enter assigned location" @tag-state="onTagState"></b-form-tags>
              </div>
            </div>
            <a-button class="btn btn-success waves-effect waves-light mt-3" :loading="updateOrganizationChart" @click.prevent="update"><i class="fa fa-edit mr-1" aria-hidden="true"></i>Update Employee</a-button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'

export default {
  name: 'update',
  components: {},
  data() {
    return {
      parent_users: [],
      departments: [],
      organization_chart: {
        parent_id: '',
        name: '',
        designation: '',
        location: '',
        phone_no: '',
        department_id: '',
        assigned_locations: [],
        parent_selected: '',
      },
      validation_errors: {},
      updateOrganizationChart: false,
      show: false,
      validTags: [],
      invalidTags: [],
      duplicateTags: [],
    }
  },
  mounted() {
    this.getCode()
    this.onUpdate()
  },
  methods: {
    getCode() {
      apiClient.get('api/organization-chart/codes')
        .then(response => {
          const data = response.data
          this.parent_users = data.parent_users
          this.departments = data.departments
        })
        .catch(error => {
          console.log(error)
        })
    },
    onUpdate() {
      const organizationChartId = this.$route.params.organization_chart_id
      apiClient.get('api/organization-chart/edit/' + organizationChartId)
        .then(response => {
          if (!response.data.error) {
            const data = response.data
            this.organization_chart = data.organization_chart
          } else {
            this.$notification.error({
              message: response.data.message,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          console.log(this.organization_chart)
          this.updateOrganizationChart = true
          apiClient.post('api/organization-chart/update/' + this.organization_chart.id, this.organization_chart)
            .then(response => {
              if (!response.data.error) {
                this.$router.push({ name: 'organizationChartList' })
                this.$notification.success({
                  message: response.data.message,
                })
              } else {
                this.$notification.error({
                  message: response.data.message,
                })
              }
            }).catch(error => {
              this.updateOrganizationChart = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    onTagState(valid, invalid, duplicate) {
      this.validTags = valid
      this.invalidTags = invalid
      this.duplicateTags = duplicate
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
    resetForm() {
      //
    },
  },
}
</script>

<style scoped>
.input-group-text {
  background-color: #ffffff !important;
}
</style>
